/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kakaocorp.com/page/detail/9814"
  }, "데이터센터 화재로 인한 카카오 대표 대국민 사과문 - 카카오")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/it/article/2022101996511"
  }, "논란 키운 카카오 장애 근본 원인은 리튬배터리…여론은 싸늘 - 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hyu.wiki/index.php?title=%EC%B9%B4%EC%B9%B4%EC%98%A4_%EB%8D%B0%EC%9D%B4%ED%84%B0%EC%84%BC%ED%84%B0&mobileaction=toggle_view_desktop"
  }, "카카오 데이터센터 - 한양 위키")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mobile.newsis.com/view.html?ar_id=NISX20221019_0002054191#_enliple"
  }, "'카카오 데이터센터' 다를까…\"화재에도 전력중단 없다\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://datacenter.navercorp.com/"
  }, "DATA CENTER GAK")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
